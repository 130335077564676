export const environment = {
  production:false,
  enableMsal: "#{enableMsal}#",

  host:'https://smart-supervision-qa.apps.ambientesbc.com',
  api: 'https://clientes-ext-qa.apps.ambientesbc.com/smart-supervision/adminrest/hmls/',
  apiProtected: 'https://clientes-ext-qa.apps.ambientesbc.com/smart-supervision/adminrest/**',
  time: "#{time}#",
  powerBi: "#{powerBi}#",

  azureConfig:{
    redirectUri: '#{redirectUri}#',
    clientId:  '#{clientId}#',
    tenantId:  '#{tenantId}#',
    resource: '#{resource}#'
  },

};

export const link_tablas_homologacion="https://www.superfinanciera.gov.co/inicio/industrias-supervisadas/interes-del-vigilado/reportes/indice-de-reportes-de-informacion-a-la-superintendencia-financiera/tablas-anexas-para-el-reporte-de-informacion--10109446"
export const link_web_service="https://www.superfinanciera.gov.co/inicio/industrias-supervisadas/interes-del-vigilado/reportes/indice-de-reportes-de-informacion-a-la-superintendencia-financiera/guias-para-el-reporte-de-informacion/documentos-tecnicos-15001"
export const link_portal_sfc="https://smart.superfinanciera.gov.co/login"
